import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { Connection, ConnectionType } from './connectors';

export function buildWalletConnectV2Connector() {
  const [web3WalletConnectV2, web3WalletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: '6cba676e11efda5cc75ea295e12ab41e',
          chains: [8453],
          showQrModal: true,
        },
      })
  );
  const walletConnectConnection: Connection = {
    connector: web3WalletConnectV2,
    hooks: web3WalletConnectV2Hooks,
    type: ConnectionType.WALLET_CONNECT_V2,
  };
  return walletConnectConnection;
}
