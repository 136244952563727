import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CONNECTORS } from './components/connectors';
import './index.scss';
import ErrorPage from './pages/error/error-page';
import Home from './pages/home';
import Root from './pages/root';
import Widget from './pages/widget';

window.Buffer = window.Buffer || require('buffer').Buffer;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  {
    path: '/widget',
    element: <Widget />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={Object.values(CONNECTORS).map((connector) => [connector.connector, connector.hooks])}>
      <RouterProvider router={router} />
    </Web3ReactProvider>
  </React.StrictMode>
);
