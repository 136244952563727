import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import { ConnectModalContext } from '../../pages/root';
import Button from '../button';
import { CONNECTORS, ConnectionType, tryDeactivateConnector } from '../connectors';
import Modal from '../modal';
import './index.scss';

// const WalletConnectConnecter = buildWalletConnectV2Connector();
// const MetamaskConnecter = buildInjectedConnector();

function ConnectButton() {
  const [isModalOpen, setIsModalOpen] = useContext(ConnectModalContext);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const connect = async (connectionType: ConnectionType) => {
    setIsModalOpen(false);
    try {
      const connector = CONNECTORS[connectionType].connector;
      // switch (connectionType) {
      // case ConnectionType.INJECTED:
      // await connector.activate(97);
      await connector.activate(8453);
      // break;
      // case ConnectionType.WALLET_CONNECT_V2:
      // await connector.activate(8453);
      // await connector.activate({
      //   chainId: 8453,
      //   chainName: 'Base Chain',
      //   nativeCurrency: 'ETH',
      //   rpcUrls: 'https://base-mainnet.public.blastapi.io',
      //   blockExplorerUrls: 'https://basescan.org',
      // });
      // break;
      // }
    } catch (error) {
      console.error(error);
    }
  };
  const logout = async () => {
    try {
      await tryDeactivateConnector(CONNECTORS.INJECTED.connector);
      await tryDeactivateConnector(CONNECTORS.WALLET_CONNECT_V2.connector);
      await tryDeactivateConnector(CONNECTORS.COINBASE_WALLET.connector);
    } catch (error) {
      console.log(error);
    }
  };
  const { account } = useWeb3React();
  return (
    <div className='connectButton'>
      <Modal className='connectModal' closeModal={() => setIsModalOpen(false)} width='300px' isModalActive={isModalOpen}>
        <div className='ModalButtons'>
          <Button value='Metamask' onClick={() => connect(ConnectionType.INJECTED)} />
          <Button value='Wallet Connect' onClick={() => connect(ConnectionType.WALLET_CONNECT_V2)} />
          <Button value='Coinbase' onClick={() => connect(ConnectionType.COINBASE_WALLET)} />
        </div>
      </Modal>

      <Button
        value={account ? account.substring(0, 6) + '...' + account.substring(account.length - 4) : 'Connect Wallet'}
        onClick={account ? logout : openModal}
        transparent={true}
      />
    </div>
  );
}

export default ConnectButton;
