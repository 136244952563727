/* eslint-disable react/no-array-index-key */
import React from 'react';
import './index.scss';

interface ModalProps {
  children: React.ReactNode;
  isModalActive: boolean;
  closeModal: () => void;
  width: string;
  className?: string;
}
function Modal({ children, isModalActive, closeModal, width, className }: ModalProps) {
  return (
    <>
      <div onClick={closeModal} className={isModalActive ? `backdrop backdrop__active ` : `backdrop`}>
        &nbsp;
      </div>
      <div className={(isModalActive ? `confirmModal confirmModal__active` : `confirmModal`) + ' ' + className} style={{ maxWidth: width }}>
        {children}
      </div>
    </>
  );
}

export default Modal;
