import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Outlet } from 'react-router-dom';
import logo from '../../assets/logo.webp';
import ConnectButton from '../../components/ConnectButton';
import './index.scss';

//@ts-ignore
export const ConnectModalContext = React.createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>();
export default function Root() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <ConnectModalContext.Provider value={[isModalOpen, setIsModalOpen]}>
      <div id='navbar'>
        <Container>
          <Row>
            <Col className='logo'>
              <a href='https://www.thefukutoshi.com/'>
                <img src={logo} alt='' />
                <h1>FUKUTOSHI</h1>
              </a>
            </Col>
            <Col className='connectButton'>
              <ConnectButton />
            </Col>
          </Row>
        </Container>
      </div>
      <div id='detail'>
        <Outlet />
      </div>
      <div id='footer'>
        <Container>
          <div className='email'>
            Contact: <a href='mailto:fukutoshicoin@gmail.com'>fukutoshicoin@gmail.com</a>
          </div>
        </Container>
      </div>
    </ConnectModalContext.Provider>
  );
}
