import './index.scss';

interface ButtonProps {
  transparent?: boolean;
  value: string;
  onClick?: () => void;
}

function Button({ transparent, value, onClick }: ButtonProps) {
  return <input className={`buttonClass${transparent ? ' transparent' : ''}`} type='button' value={value} onClick={onClick} />;
}
export default Button;
