import { Web3ReactHooks } from '@web3-react/core';
import { Connector } from '@web3-react/types';
import { buildWalletConnectV2Connector } from './WalletConnectV2';
import { buildCoinbaseWalletConnector } from './coinbase';
import { buildInjectedConnector } from './injected';

export enum ConnectionType {
  INJECTED = 'INJECTED',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  COINBASE_WALLET = 'COINBASE_WALLET',
}

export const CONNECTORS: { [key in ConnectionType]: Connection } = {
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  [ConnectionType.WALLET_CONNECT_V2]: buildWalletConnectV2Connector(),
  [ConnectionType.COINBASE_WALLET]: buildCoinbaseWalletConnector(),
};

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

export function onConnectionError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

export const tryDeactivateConnector = async (connector: Connector): Promise<null | undefined> => {
  connector.deactivate?.();
  connector.resetState();
  return null;
};
