import ProgressBar from '@ramonak/react-progress-bar';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import fukuNftAbi from '../../abi/fukuNft.json';
import CatImage from '../../assets/FukoCat.webp';
import Cat from '../../components/Cat';
import Button from '../../components/button';
import { baseRPC, fukuNFTAddress } from '../../constants';
import './index.scss';

function Home() {
  const totalNFTs = 8888;
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  // const { account, chainId } = useWeb3React();
  const [price, setPrice] = useState(0.004);
  const [numAvailableNfts, setNumAvailableNfts] = useState(totalNFTs);
  // const mint = () => {
  // const contract = new ethers.Contract(fukuAddress, fukuAbi, signer);
  // signer
  // ethers
  // };
  useEffect(() => {
    async function getprice() {
      try {
        const simplerpc = new ethers.providers.JsonRpcProvider(baseRPC);
        const contract = new ethers.Contract(fukuNFTAddress, fukuNftAbi, simplerpc);
        const [cost, totalSupply] = await Promise.all([contract.cost(), contract.totalSupply()]); //, contract.isApprovedForAll(account)
        setPrice(new BigNumber(cost.toString()).dividedBy(1e18).toNumber());
        setNumAvailableNfts(totalNFTs - totalSupply.toNumber());
      } catch (error) {
        console.error(error);
      }
      // const res = await Promise.all([contract.getUnpaidEarnings(address), contract.totalRewardsDistributed(address)]);
    }
    getprice();
  }, []);
  return (
    <div className='mintPage'>
      {/* <Modal className='learnMoreModal' closeModal={() => setIsModalOpen(false)} width='500px' isModalActive={isModalOpen}>
        <div className='rarityTable'>Rarity table</div>
        <table></table>
      </Modal> */}
      <Container>
        <Row>
          <Col lg='6' className='mainText'>
            <h1>🍀 The Fukutoshi NFT - Your Gateway to Digital Luck 🍀</h1>
            {/* </Col>
        </Row>
        <Row>
          <Col lg='6'> */}
            <p>
              Discover endless charm and luck with this adorable Fukutoshi NFT. Embrace the symbol of prosperity as its raised paw brings positivity to your
              collection. But that's not all – minting this NFT unlocks a wave of fortune, granting you not just 1, but 8 additional NFTs, each with its own
              unique touch. Tradition meets whimsy, bringing you both a timeless emblem and a shower of digital treasures. Among the grand total of {totalNFTs}{' '}
              NFTs, a select 10 shine even brighter, carrying an extra touch of exclusivity and artistry. With this release, you have the chance to own a piece
              of art that blends tradition, luck, and modern digital craftsmanship.
            </p>
            <a href='https://www.thefukutoshi.com/'>
              <Button value='Learn More' />
            </a>
          </Col>
          <Col lg='6' className='imageHolder'>
            <Cat numAvailableNfts={numAvailableNfts} price={price} />
            <br />
            <div className='progressBar'>
              <ProgressBar
                completed={Math.round(((totalNFTs - numAvailableNfts) / totalNFTs) * 100)}
                bgColor='#fc4e1a'
                customLabelStyles={{ paddingRight: '10px' }}
              />
            </div>
            <br />
            {numAvailableNfts} / {totalNFTs} FUKUTOSHIS LEFT
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <img src={CatImage} alt='NFT' style={{ width: '400px', maxWidth: '100%' }} />
          </Col>
          <Col className='telegram' xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }}>
            <h1>Join our Paw-some $FUKU Community</h1>
            <p>Stay Up-To-Date With The Latest News</p>
            <a href='https://t.me/fukutoshi' target='_blank' rel='noreferrer'>
              <Button value='Join Telegram' />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
