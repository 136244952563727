import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useContext, useState } from 'react';
import fukuNftAbi from '../../abi/fukuNft.json';
import CatImage from '../../assets/loadingNft.gif';
import minus from '../../assets/minus.png';
import plus from '../../assets/plus.png';
import { fukuNFTAddress } from '../../constants';
import { ConnectModalContext } from '../../pages/root';
import Button from '../button';
import './index.scss';

interface CatProps {
  numAvailableNfts: number;
  price: number;
}

function Cat({ numAvailableNfts, price }: CatProps) {
  const [amount, setAmount] = useState(1);
  const [, setIsModalOpen] = useContext(ConnectModalContext);
  const { account, provider, chainId } = useWeb3React();
  const signer = provider?.getSigner();
  async function mint() {
    const contract = new ethers.Contract(fukuNFTAddress, fukuNftAbi, signer);
    try {
      if (chainId === 8453) {
        const res = await contract.publicMint(amount.toString(), {
          value: new BigNumber(amount).multipliedBy(price).multipliedBy(1e18).toString(),
        });
        console.log(res);
      } else {
        window.alert('Not on Base chain (8453)');
      }
    } catch (error) {
      window.alert('Insufficent funds');
      // console.error(error);
    }
  }
  // async function approve() {
  //   const contract = new ethers.Contract(fukuNFTAddress, fukuNftAbi, signer);
  //   const res = await contract.setApproveForAll(true);
  //   console.log(res);
  // }

  return (
    <div className='cat'>
      <img src={CatImage} alt='NFT' />
      <div className='float'>
        {numAvailableNfts === 0 ? (
          'Sold out'
        ) : (
          <>
            <div className='amount'>
              <div className='header'>Amount</div>
              <div className='value'>
                <img src={minus} alt='Minus' onClick={() => setAmount((prev) => Math.max(prev - 1, 1))} />
                {amount}&nbsp;(+&nbsp;{7 * amount}&nbsp;Free)
                <img src={plus} alt='Plus' onClick={() => setAmount((prev) => Math.min(prev + 1, numAvailableNfts))} />
              </div>
            </div>
            <div className='amount'>
              <div className='header'>Price</div>
              <div className='value'>{new BigNumber(amount).multipliedBy(price).toFixed(3)} ETH</div>
            </div>
            <div className='mintButton'>
              <Button value='Mint' onClick={!account ? () => setIsModalOpen(true) : mint} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Cat;
