import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import fukuAbi from '../../abi/fuku.json';
import Dots from '../../components/Dots';
import { baseRPC, fukuAddress } from '../../constants';
import './index.scss';

type IData = {
  unpaid: string;
  totalRewards: string;
};

function Widget() {
  const [wallet, setWallet] = useState('');
  const [errorTxt, setErrorTxt] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<null | IData>(null);

  const getData = useCallback(async (address: string) => {
    setLoading(true);
    try {
      const simplerpc = new ethers.providers.JsonRpcProvider(baseRPC);
      const contract = new ethers.Contract(fukuAddress, fukuAbi, simplerpc);
      const res = await Promise.all([contract.getUnpaidEarnings(address), contract.totalRewardsDistributed(address)]);
      const unpaid = new BigNumber(res[0].toString());
      const totalRewards = new BigNumber(res[1].toString());
      setData({ unpaid: unpaid.dividedBy(1e18).toFixed(6), totalRewards: totalRewards.dividedBy(1e18).toFixed(6) });
    } catch (error) {
      console.error(error);
      setErrorTxt('Something went wrong');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setErrorTxt('');
    setData(null);
    if (wallet.trim().match(/^0x[a-fA-F0-9]{40}$/g)) {
      getData(wallet.trim().toLowerCase()); //must be lowercase for bad checksum
    } else if (wallet.length !== 0) {
      setErrorTxt('Please enter a valid address');
    }
  }, [getData, wallet]);

  return (
    <div className='Widget'>
      <header>Wallet Rewards Stats:</header>
      <input onChange={(e) => setWallet(e.target.value)} placeholder='0x123...abcd' />
      {errorTxt ? (
        <p>{errorTxt}</p>
      ) : loading ? (
        <p>
          <Dots />
        </p>
      ) : (
        data && (
          <p>
            Total Rewards: {data.totalRewards} ETH
            <br />
            Unpaid Rewards: {data.unpaid} ETH
          </p>
        )
      )}
    </div>
  );
}

export default Widget;
